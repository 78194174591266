.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #1f2f4d;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
}

.swiper-slide img {
    margin: 2rem 0rem;
    display: block;
    width: 65%;
    height: 65%;
    object-fit: cover;
}

.swiper-button-next {
    color: black!important;
}

.swiper-button-prev {
    color: black!important;
}

.mapContainer {
    width: 100%!important;
    height: 100%!important;
    @media only screen and (max-width: 768px) {
        height: 50vh!important;
    }
    
}


